@media screen and (max-width: 400px) {
  .first ,

  .intro ,
  #features,
  
  #about,
  #Vision,
  #testimonials,
  #team,
  #contact,
  #footer,

  #portfolio {
    width: auto;
    
  }
  
  
}
@media screen and (max-width: 650px) {
  .intro-text > h1 {
    font-size: 40px;
  }
  
  
}
